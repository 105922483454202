<template>
  <vee-form class="px-5" :validation-schema="peppolSchema">
    <!-- country -->
    <ion-item
      :class="['fs-2 custom-item', 'item-' + getColorValidate('selectedCountryId', errors.selectedCountryId)]"
    >
      <ion-label :color="getColorValidate('selectedCountryId', errors.selectedCountryId)" position="floating">
        {{ $t('country') }} *
      </ion-label>
      <ion-select
        placeholder="Select One"
        :value="selectedCountryId"
        v-model="selectedCountryId"
        :okText="$t('OK')"
        :cancelText="$t('cancel')"
      >
        <ion-select-option v-for="country in filteredCountries" :key="country.id" :value="country.id">
          {{ country.description }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-text v-if="errors.selectedCountryId" color="danger">
      <div class="pr-1 py-1 font-size-12">{{ $t(`${errors.selectedCountryId}`) }}</div>
    </ion-text>

    <!-- peppol scheme -->
    <ion-item
      :class="[
        'fs-2 custom-item',
        'item-' + getColorValidate('selectedPeppolScheme', errors.selectedPeppolScheme)
      ]"
    >
      <ion-label
        :color="getColorValidate('selectedPeppolScheme', errors.selectedPeppolScheme)"
        position="floating"
      >
        {{ $t('accountPage.peppol_scheme') }} *
      </ion-label>
      <ion-select
        placeholder="Select One"
        :value="selectedPeppolScheme"
        v-model="selectedPeppolScheme"
        :okText="$t('OK')"
        :cancelText="$t('cancel')"
      >
        <ion-select-option v-for="peppol in filteredPeppolSchemes" :key="peppol.id" :value="peppol">
          {{ peppol.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-text v-if="errors.selectedPeppolScheme" color="danger">
      <div class="pr-1 py-1 font-size-12">{{ $t(`${errors.selectedPeppolScheme}`) }}</div>
    </ion-text>

    <!-- description -->
    <ion-item class="fs-2 custom-item">
      <ion-label position="floating" color="medium">
        {{ $t('accountPage.peppol_scheme_description') }}
      </ion-label>
      <ion-input
        :value="selectedPeppolScheme ? selectedPeppolScheme.description : null"
        name="description"
        placeholder=""
        type="text"
        disabled
      ></ion-input>
    </ion-item>

    <!-- peppolId -->
    <ion-item :class="['fs-2 custom-item', 'item-' + getColorValidate('peppolId', errors.peppolId)]">
      <ion-label :color="getColorValidate('peppolId', errors.peppolId)" position="floating">
        {{ $t('accountPage.peppol_identifier') }}
      </ion-label>
      <ion-input
        v-model="peppolId"
        :value="peppolId"
        name="peppolId"
        placeholder=""
        type="text"
        @ionInput="onInputChange"
      ></ion-input>
    </ion-item>
    <ion-text :color="getColorValidate('peppolId', errors.peppolId)">
      <div class="pr-1 py-1 font-size-12">
        {{ errors.peppolId ? $t(`${errors.peppolId}`) : 'Example: SGUEN201302761R' }}
      </div>
    </ion-text>

    <ion-button class="btn-save mt-5" expand="full" @click="requestProduct">{{ $t('save') }}</ion-button>
  </vee-form>
</template>

<script>
import { defineComponent } from 'vue';
import { useForm, useField, Form as VeeForm } from 'vee-validate';
import { isPeppolIdentifier } from '@/modules/b2b/services/libs/helper';

import { createNamespacedHelpers } from 'vuex';
import { ACTIONS } from '@/modules/b2b/store/account/actions';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/account');

export default defineComponent({
  name: 'PeppolForm',
  components: {
    VeeForm
    // User Defined component
  },
  data() {
    return {
      firstChange: {
        selectedCountryId: false,
        selectedPeppolScheme: false,
        peppolId: false
      },
      peppolSchemeId: null,
      countries: [],
      filteredCountries: [],
      peppolSchemes: [],
      filteredPeppolSchemes: []
    };
  },
  watch: {
    selectedCountryId(id) {
      this.selectedPeppolScheme = null;
      this.filteredPeppolSchemes = this.peppolSchemes.filter((v) => {
        return v.country_id === id;
      });
      if (this.filteredPeppolSchemes.length === 1) {
        this.selectedPeppolScheme = this.filteredPeppolSchemes[0];
      }
    },
    selectedPeppolScheme(v) {
      this.peppolSchemeId = v ? v.id : null;
    }
  },
  computed: {
    ...mapGetters(['peppolSchemesAndCountries', 'connectPeppolPage'])
  },
  inject: ['$storage'],

  async mounted() {
    this.selectedCompany = await this.$storage.getSelectedCompany();
    await Promise.all([
      this[ACTIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES](),
      this[ACTIONS.GET_CONNTECT_PEPPOL_PAGE]({ customerId: this.selectedCompany.id })
    ]);
    // peppolSchemesAndCountries
    this.countries = this.peppolSchemesAndCountries.countries || [];
    this.filteredCountries = [...this.countries];
    this.peppolSchemes = this.peppolSchemesAndCountries.peppolSchemes || [];

    // connectPeppolPage
    this.peppolId = this.connectPeppolPage.peppolId;
    this.peppolSchemeId = this.connectPeppolPage.peppolSchemeId;
    this.selectedCountryId = this.connectPeppolPage.countryId;

    await this.$nextTick(); // wait for selectedCountryId watcher first.

    this.selectedPeppolScheme = this.peppolSchemes.find(
      (v) => v.id === this.connectPeppolPage.peppolSchemeId
    );
  },
  setup() {
    // Define a validation schema
    const peppolSchema = {
      selectedCountryId: (val) => (!val ? 'accountPage.please_choose_something' : true),
      selectedPeppolScheme: (val) => (!val ? 'accountPage.please_choose_something' : true),
      peppolId: (val) =>
        !val
          ? 'accountPage.cannot_be_empty'
          : !isPeppolIdentifier(val)
          ? 'accountPage.must_be_a_peppol_identifier'
          : true
    };

    // Create a form context with the validation schema
    const { errors, validate, values: product } = useForm({
      validationSchema: peppolSchema,
      initialValues: {
        selectedCountryId: null,
        selectedPeppolScheme: undefined,
        peppolId: null
      }
    });

    const { value: selectedCountryId } = useField('selectedCountryId');
    const { value: selectedPeppolScheme } = useField('selectedPeppolScheme');
    const { value: peppolId } = useField('peppolId');

    return {
      peppolSchema,
      errors,
      validate,
      product,

      // form data
      selectedCountryId,
      selectedPeppolScheme,
      peppolId
    };
  },
  emits: ['connect-peppol'],
  methods: {
    ...mapActions([ACTIONS.GET_PEPPOL_SCHEME_AND_COUNTRIES, ACTIONS.GET_CONNTECT_PEPPOL_PAGE]),
    getColorValidate(name, errMsg) {
      if (this.firstChange[name]) {
        return errMsg ? 'danger' : 'primary';
      }
      return 'medium';
    },
    onInputChange(event) {
      this.firstChange[event.target.name] = true;
    },
    async requestProduct() {
      // set first change is true
      for (const key in this.firstChange) {
        this.firstChange[key] = true;
      }
      // validate form
      const { valid } = await this.validate();
      if (valid) {
        const peppol = {
          peppolId: this.peppolId,
          peppolSchemeId: this.peppolSchemeId,
          scheme: this.selectedPeppolScheme.name
        };
        this.$emit('connect-peppol', peppol);
      }
    }
  }
});
</script>
