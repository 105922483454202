<template>
  <ion-page>
    <simple-header backHref="/b2b/account" :title="$t('accountPage.peppol_id_configuration')" />
    <ion-content fullscreen>
      <peppol-form @connect-peppol="connectPeppol" />
    </ion-content>

    <!-- modal info -->
    <ion-modal
      :is-open="isOpenModalInfoRef"
      css-class="modal-info custom-modal"
      @didDismiss="setOpenModalInfo(false)"
    >
      <modal-content-info
        :title="modalInfoTitle"
        :content="modalInfoContent"
        @close-modal="setOpenModalInfo(false)"
      >
      </modal-content-info>
    </ion-modal>

    <!-- loading -->
    <ion-loading
      :is-open="isOpenLoadingref"
      @didDismiss="setOpenLoading(false)"
      cssClass="custom-loading"
      message=""
      spinner="crescent"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import SimpleHeader from '@/modules/b2b/views/shared/SimpleHeader.vue';
import PeppolForm from './PeppolForm.vue';
import ModalContentInfo from '@/modules/b2b/views/shared/ModalContentInfo.vue';
import { createNamespacedHelpers } from 'vuex';
import { ACTIONS } from '@/modules/b2b/store/account/actions';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/account');

export default defineComponent({
  name: 'ConnectPeppol',
  components: {
    // User Defined component
    SimpleHeader,
    PeppolForm,
    ModalContentInfo
  },
  inject: ['$storage'],
  computed: {
    ...mapGetters(['status', 'error'])
  },
  data() {
    return {
      selectedCompany: {},

      titleSuccess: this.$t('success'),
      titleFailed: this.$t('failed'),
      modalInfoTitle: '',
      modalInfoContent: '',
      contentSuccessPeppol: this.$t('accountPage.peppol_id_configured_successfully')
    };
  },
  setup() {
    // modal info
    const isOpenModalInfoRef = ref(false);
    const setOpenModalInfo = (state) => (isOpenModalInfoRef.value = state);

    // loading
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    return {
      // modal info
      isOpenModalInfoRef,
      setOpenModalInfo,
      // loading
      isOpenLoadingref,
      setOpenLoading
    };
  },
  async mounted() {
    this.selectedCompany = await this.$storage.getSelectedCompany();
  },
  methods: {
    ...mapActions([
      ACTIONS.REGISTER_PEPPOL,
      ACTIONS.UPDATE_CUSTOMER_PEPPOL,
      ACTIONS.GET_CONNTECT_PEPPOL_PAGE
    ]),
    async connectPeppol(peppol) {
      this.setOpenLoading(true);

      const registerPeppolParams = {
        scheme: peppol.scheme,
        id: peppol.peppolId
      };
      await this[ACTIONS.REGISTER_PEPPOL](registerPeppolParams);
      const customerPeppolParams = {
        customerId: this.selectedCompany.id,
        peppolId: peppol.peppolId,
        peppolSchemeId: peppol.peppolSchemeId
      };
      await this[ACTIONS.UPDATE_CUSTOMER_PEPPOL](customerPeppolParams);
      this.setOpenLoading(false);

      // show modal info
      if (this.status) {
        this.modalInfoTitle = this.titleSuccess;
        this.modalInfoContent = this.contentSuccessPeppol;
        this[ACTIONS.GET_CONNTECT_PEPPOL_PAGE]({ customerId: this.selectedCompany.id });
      } else {
        this.modalInfoTitle = this.titleFailed;
        this.modalInfoContent = this.error.message;
        this[ACTIONS.RESET_STATUS_ERROR]();
      }
      this.setOpenModalInfo(true);
    }
  }
});
</script>
